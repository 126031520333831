<template>
<div class="my-4">
  <b-container>
    <b-row class="text-center mb-4" align-h="center" v-if="loadingTopics">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingTopics">
      <b-col>
        <b-card class="mb-3">
          <b-button class="mr-2" v-if="permissionAddTopic" variant="outline-primary" v-on:click="getXls">download</b-button>
          <b-button class="mr-2" v-if="permissionAddTopic" variant="outline-primary" :to="{ name: 'TopicAdd' }">add topic</b-button>
          <b-button class="mr-2" v-if="permissionRegtopicgroup" variant="outline-primary" :to="{ name: 'Regtopicgroups' }">topic groups</b-button>
          <b-form-group horizontal class="mt-4">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
            </b-input-group>
          </b-form-group>
        </b-card>

        <b-card >
          <div v-for="group in showTopics" :key="'group' + group.id">
            <div class="mb-3">
              <h4>{{group.name}}</h4>
              <div v-for="topic in group.regtopics" :key="'group' + group.id + 'topic' + topic.id">
                <b-link :to="{ name: 'Topic', params: { id: topic.id }}">{{topic.name}}</b-link>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import zipcelx from 'zipcelx'

import ac from '../libs/accesscontrol'

export default {
  name: 'Topics',
  computed: {
    showTopics: function () {
      const topics = JSON.parse(JSON.stringify(this.topics))
      let results = []
      if (this.filter.length > 2) {
        _.each(topics, x => {
          results.push(x)
        })
        _.each(results, x => {
          x.regtopics = x.regtopics.filter(y => y.name.toUpperCase().includes(this.filter.toUpperCase()))
        })
      } else {
        _.each(topics, x => {
          results.push(x)
        })
      }
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'topics', action: 'open topics' })
    this.permissionAddTopic = ac.can(this.user.acgroups).createAny('topic').granted
    this.permissionRegtopicgroup = ac.can(this.user.acgroups).readAny('regtopicgroup').granted
    this.loadTopics()
  },
  data () {
    return {
      buttonvariant: 'primary',
      event: {},
      filter: '',
      permissionAddTopic: false,
      permissionRegtopicgroup: false,
      loadingTopics: true,
      topics: ''
    }
  },
  methods: {
    getXls: async function () {
      this.$stat.log({ page: 'topics', action: 'download topics' })
      let topics = []
      try {
        topics = await this.$Amplify.API.get('cosmos', '/topics')
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
      let data = []
      const headings = [
        { value: 'TopicId', type: 'string' },
        { value: 'Title', type: 'string' },
        { value: 'Details', type: 'string' },
        { value: 'Updates', type: 'string' },
        { value: 'Tracking status', type: 'string' },
        { value: 'Sectors', type: 'string' },
        { value: 'Categories', type: 'string' }
      ]
      data.push(headings)
      for (let i = 0, len = topics.length; i < len; i++) {
        let row = [
          { value: topics[i].id, type: 'string' },
          { value: topics[i].name, type: 'string' },
          { value: topics[i].comments, type: 'string' },
          { value: topics[i].updates, type: 'string' },
          { value: topics[i].trackingStatus, type: 'string' },
          { value: JSON.stringify(topics[i].sectors), type: 'string' },
          { value: JSON.stringify(topics[i].categoriesName), type: 'string' }
        ]
        data.push(row)
      }
      const config = { filename: 'reg-topics', sheet: { data: data } }
      zipcelx(config)
    },
    loadTopics: async function () {
      this.$logger.debug('loadTopics started')
      this.loadingTopics = true
      try {
        const topics = await this.$Amplify.API.get('cosmos', '/topics/bygroup')
        this.topics = topics
        this.loadingTopics = false
        this.$logger.debug(this.topics)
      } catch (e) {
        this.$logger.wrn('saved ERROR: ', e)
      }
    }
  }
}
</script>

<style>
</style>
